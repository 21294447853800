import { motion, useInView, useScroll, useTransform } from 'framer-motion';
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import visionImg from "../../../../assets/images/vision.webp";
import "./Vision.scss";

const Vision = () => {
    const width = document.body.clientWidth;
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end center"],
    })
    const y = useTransform(scrollYProgress, [0, 1], [100, -100]);


    const y2 = useTransform(scrollYProgress, [1, 0], [100, -100]);


    const imgRef = useRef(null);
    const isInView = useInView(imgRef);
    const variant = {
        initial: {
            clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
        },
        final: {
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
        }
    };
    return (
        <>
            <section id="vision" ref={ref} className='vision_sec'>
                <Container>
                    <div className='vision_box'>
                        <div className="vision_in">
                            <motion.div
                                ref={imgRef}
                                className="vision_right"
                                initial={"initial"}
                                variants={width > 767 && variant}
                                animate={isInView ? "final" : ""}
                                transition={{
                                    duration: 0.7,
                                }}
                            >
                                <motion.img
                                    style={width > 767 && { y }}
                                    src={visionImg}
                                    alt=""
                                />
                            </motion.div>
                            <div className="vision_left">
                                <h2 title="Vision">Vision<motion.div style={width > 767 && { y: y2 }}>Vision</motion.div></h2>
                                <p>Our vision is to simplify and democratize access to real-world assets, enabling everyone to invest in tangible opportunities effortlessly. FigFi is committed to breaking down barriers, fostering inclusivity, and empowering global prosperity through blockchain-based investment solutions.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Vision
