import React from 'react';
import "./TitleText.scss";

const TitleText = ({ text, children, className }) => {
    return (
        <>
            <h2 className={`title_text ${className || ""}`}>{text || children}</h2>
        </>
    )
}

export default TitleText
