import { useAnimate, motion, useInView } from 'framer-motion';
import React, { useRef } from 'react'
import { getCoords } from '../../../../utils/utils';

const FeatureCard = ({ title, description, className, imgs }) => {
    const [scope, animate] = useAnimate();
    const shadow = useRef(null);
    const width = document.body.clientWidth;
    const getSmallScaleValue = (total, value) => {
        return -5 + value / (total / 10);
    }

    function handleMouse(event) {
        let width = event.currentTarget.clientWidth,
            height = event.currentTarget.clientHeight;

        animate(
            scope.current,
            {
                rotateY: getSmallScaleValue(width, event.pageX - getCoords(event.currentTarget).left),
                rotateX: -getSmallScaleValue(height, event.pageY - getCoords(event.currentTarget).top)
            },
            {
                type: "tween",
                duration: 0,
            }
        )
        animate(
            shadow.current,
            {
                opacity: 1,
                x: event.pageX - getCoords(event.currentTarget).left,
                y: event.pageY - getCoords(event.currentTarget).top,
            },
            {
                type: "tween",
                duration: 0,
            }
        )
    }


    const ref = useRef(null);
    const isInView = useInView(ref);
    const variant = {
        initial: {
            clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
        },
        final: {
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
        }
    };

    return (
        <>
            <motion.div
                onMouseMove={width > 767 ? handleMouse : () => { }}
                ref={ref}
                onMouseLeave={() => {
                    if (width > 767) {
                        animate(scope.current, { rotateX: 0, rotateY: 0, });
                        animate(
                            shadow.current,
                            {
                                opacity: 0,
                            },
                            {
                                type: "tween",
                                duration: 0,
                            }
                        )
                    }
                }}
                className={`feature_box_outer`}
                initial={"initial"}
                variants={width > 767 && variant}
                animate={isInView ? "final" : ""}
                transition={{
                    duration: 0.4,
                }}
            >
                <div
                    ref={scope}
                    className={`feature_box ${className || ""}`}>
                    <div className='feature_box_shadow' ref={shadow} />
                    <h3>{title}</h3>
                    {description && <p>{description}</p>}
                    <div className="feature_box_imgs">
                        {
                            imgs.map(item => (
                                <img src={item} key={item} alt="" />
                            ))
                        }
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default FeatureCard
