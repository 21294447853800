import React from 'react'
import logo from "../../../assets/logo/logo.png";
import "./Footer.scss";
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SOCIAL_LINKS } from '../../../utils/constant';
import { DocsIcon, TelegramIcon, TwitterIcon } from '../../../assets/icons/icons';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <Container>
                    <div className="footer_in">
                        <p className="copyright_txt">© {(new Date()).getFullYear()} Figfi</p>
                        <Link to="/" className='footer_logo'>
                            <img src={logo} alt="figfi" />
                        </Link>
                        <ul>
                            <li>
                                <Link to={SOCIAL_LINKS.TELEGRAM}><TelegramIcon /></Link>
                            </li>
                            <li>
                                <Link to={SOCIAL_LINKS.DOCS}><DocsIcon /></Link>
                            </li>
                            <li>
                                <Link to={SOCIAL_LINKS.TWITTER}><TwitterIcon /></Link>
                            </li>
                        </ul>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer
