import React from 'react'
import "./Layout.scss";
import Footer from '../../common/Footer/Footer';
import Header from '../../common/Header/Header';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <>
            <main className='layout'>
                <Header />
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default Layout
