import React from 'react';
import "./Button.scss";

const Button = ({ className, title, disabled, text, children, fluid, ...rest }) => {
    return (
        <>
            <button
                {...rest}
                className={`btn_custom ${className || ""} ${fluid ? "w-100" : ""}`}
                title={title}
                disabled={disabled}
            >
                {
                    text || children
                }
            </button>
        </>
    )
}

export default Button
