import { Container } from "react-bootstrap";
import "./Roadmap.scss";
import Slider from "react-slick";
import RoadmapBox from "./RoadmapBox";
import TitleText from "../../../common/TitleText/TitleText";

const Roadmap = () => {
    const roadmap = [
        {
            phase: "1",
            list: [
                "Website Launched",
                "White Paper",
                "Presale",
                "Fig Staking ",
                "Solana Staking",
                "Lending and Borrow Testnet",
            ],
        },
        {
            phase: "2",
            list: [
                "RWA Swap",
                "Product Dashboard",
                "Real Estate Dapp",
                "Partners onboarding",
                "Lending and Borrow Mainet",
                "Marketing Campaign",
            ],
        },
        {
            phase: "3",
            list: [
                "Commodities Dapp",
                "Fintech License",
                "Stocks Trading",
                "iOS Mobile App Release ",
                "Platform Governance",
                "Cross-Chain Compatibility",
                "Automated Portfolios",
                "Marketing Campaign",
            ],
        },
    ];
    const settings = {
        slidesToShow: 3,
        infinite: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 499,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }
    return (
        <>
            <section id="roadmap" className="roadmap">
                <Container>
                    <TitleText>Roadmap</TitleText>
                    <Slider {...settings} className="roadmap_list">
                        {
                            roadmap.map(item => (
                                <RoadmapBox title={item.phase} list={item.list} key={item.title} />
                            ))
                        }
                    </Slider>
                </Container>
            </section>
        </>
    )
}

export default Roadmap
