import { animate, stagger, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import leftQuote from "../../../../assets/images/inverted-left.png";
import rightQuote from "../../../../assets/images/inverted-right.png";
import "./QuoteText.scss";

const QuoteText = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    useEffect(() => {
        if (document.body.clientWidth > 767) {
            animate("#quote > div > div", {
                y: [100, 0],
                opacity: [0, 1]
            }, {
                duration: 0.5,
                delay: stagger(0.2),
            })
        }
    }, [isInView])
    return (
        <>
            <section ref={ref} className="quote_text">
                <Container>
                    <div className="quote_txt_in">
                        <img src={leftQuote} alt="" />
                        <h2 id="quote">
                            <div className="d-sm-inline-block d-none"><div>The Next step going forward will be the</div></div>
                            <div className="d-sm-inline-block d-none"><div>Tokenization of Financial Assets, and that means</div></div>
                            <div className="d-sm-inline-block d-none"><div>every stock, every bond will be tokenized</div></div>
                            <div className="d-sm-none">The Next step going forward will be the Tokenization of Financial Assets, and that means every stock, every bond will be tokenized</div>
                            <span className="quote_border"></span>
                        </h2>
                        <div className="quote_box">
                            <h3>Larry Fink</h3>
                            <p>CEO of Blackrock</p>
                        </div>
                        <img src={rightQuote} alt="" />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default QuoteText
