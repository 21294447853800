import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import BannerImg1 from "../../../../assets/images/banner/1.png";
import BannerImg2 from "../../../../assets/images/banner/2.png";
import BannerImg3 from "../../../../assets/images/banner/3.png";
import BannerImg3Dark from "../../../../assets/images/banner/3-dark.png";
import BannerImg4 from "../../../../assets/images/banner/4.png";
import BannerImg5 from "../../../../assets/images/banner/5.png";
import BannerImg6 from "../../../../assets/images/banner/6.png";
import BannerImg6Dark from "../../../../assets/images/banner/6-dark.png";
import BannerImg7 from "../../../../assets/images/banner/7.png";
import BannerImg7Dark from "../../../../assets/images/banner/7-dark.png";
import Solana from "../../../../assets/images/solana.png";
import Button from "../../../common/Button/Button";
import "./Banner.scss";
import { useSelector } from "react-redux";

const Banner = () => {
	const ref = useRef(null);
	const { theme } = useSelector(state => state.theme);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start end", "end center"],
	});
	const y = useTransform(scrollYProgress, [0, 1], [400, -100]);
	const y2 = useTransform(scrollYProgress, [0, 1], [200, -100]);
	const y3 = useTransform(scrollYProgress, [0, 1], [300, -100]);
	const y4 = useTransform(scrollYProgress, [0, 1], [250, -100]);
	const y5 = useTransform(scrollYProgress, [0, 1], [100, -100]);
	const y6 = useTransform(scrollYProgress, [0, 1], [200, -100]);
	const y7 = useTransform(scrollYProgress, [0, 1], [350, -100]);
	return (
		<div id="home" ref={ref} className="banner">
			<Container >
				<Button className="solana_btn">
					Built On
					<img src={Solana} alt="Solana" className="w-6" />
					<span>
						Solana
					</span>
				</Button>
				<div className="banner_heading">
					<h1 className="asset">
						One Stop Portal to <span>Real World Assets</span>
					</h1>
					<h2 className="invest">
						Invest in&nbsp;
						<TypeAnimation
							sequence={[
								"Real Estate",
								2000,
								"Commodites",
								2000,
								"Metals",
								2000,
								"Stocks",
								2000,
								"Startups",
								2000,
							]}
							wrapper="span"
							cursor={true}
							repeat={Infinity}
							className="typing_text"
							style={{ display: "inline-block" }}
						/>
					</h2>
				</div>
				<div className="banner_img">
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y : 0, }}
						src={BannerImg1}
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y2 : 0, }}
						src={BannerImg2}
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y3 : 0, }}
						src={theme === "light" ? BannerImg3 : BannerImg3Dark}
						// className="dark_img"
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y4 : 0, }}
						src={BannerImg4}
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y5 : 0, }}
						src={BannerImg5}
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y6 : 0, }}
						src={theme === "light" ? BannerImg6 : BannerImg6Dark}
						// src={theme === "light" ? BannerImg6 : BannerImg6Dark}
						// className="dark_img"
						alt="banner"
					/>
					<motion.img
						style={{ y: document.body.clientWidth > 767 ? y7 : 0, }}
						src={theme === "light" ? BannerImg7 : BannerImg7Dark}
						// className="dark_img"
						alt="banner"
					/>
				</div>
			</Container>
		</div>
	);
};

export default Banner;
