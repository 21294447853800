import React, { useEffect } from 'react';
import Layout from './components/layout/Layout/Layout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Lenis from '@studio-freight/lenis';
import { useSelector } from 'react-redux';
import Home from './components/pages/Home/Home';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />
        }
      ]
    }
  ])
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    document.body.className = `${theme}-theme`;
  }, [theme])

  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
