import { animate, motion, stagger } from "framer-motion";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Link as Navlink } from 'react-scroll';
import { MoonIcon, SunIcon } from "../../../assets/icons/icons";
import logo from '../../../assets/logo/logo.png';
import { setTheme } from "../../../redux/Slices/theme.slice";
import './Header.scss';

function Header() {
	const [active, setActive] = useState(false);
	const { theme } = useSelector(state => state.theme);
	const dispatch = useDispatch();
	const navs = [
		{
			to: "home",
			label: "Home",
		},
		{
			to: "vision",
			label: "Vision",
			offset: -200,
		},
		{
			to: "features",
			label: "Features",
			offset: -200,
		},
		// {
		// 	to: "tokenomics",
		// 	label: "Tokenomics",
		// 	offset: -200,
		// },
		{
			to: "roadmap",
			label: "Roadmap",
			offset: -200,
		},
		{
			to: "",
			label: "Docs",
			isComingSoon: true,
		},
	];
	const handleSidebar = () => document.body.clientWidth < 991 && setActive(!active);
	useEffect(() => {
		animate("#navbar li", { y: [-100, 0], filter: ["blur(10px)", "blur(0px)"] }, { delay: stagger(0.05) })
	}, [])
	useEffect(() => {
		if (document.body.clientWidth < 991) {
			animate("#navbar li", { opacity: [0, 1], x: [-100, 0], filter: ["blur(10px)", "blur(0px)"] }, { delay: stagger(0.05) })
		}
	}, [active])
	return (
		<header className="header">
			<Container>
				<div className="header_in">
					<Link to="/" className="header_logo">
						<img
							src={logo} // Update the path to your actual logo image
							alt="figmi"
						/>
						<span>FigFi</span>
					</Link>
					<div className={`header_nav ${active ? "active" : ""}`} id="navbar">
						<ul>
							{
								navs.map(item => (
									<li key={item.label}>
										<Navlink
											to={item.to}
											activeClass={"active"}
											spy={true}
											smooth
											offset={item.offset || -60}
											onClick={!item.isComingSoon && handleSidebar}
											className={item.isComingSoon ? "coming_soon" : ""}
										>{item.label}</Navlink>
									</li>
								))
							}
						</ul>
					</div>
					<div className="header_action">
						{/* <Button className="coming_soon">Go to DApp <RightArrow /></Button> */}
						<button>
							<label onClick={() => dispatch(setTheme(theme !== "light" ? "light" : "dark"))} className={`${theme === "light" ? "light" : "dark"} theme_toggler`}>
								<span className="theme_toggler_btn">
									<SunIcon />
									<motion.span layout></motion.span>
									<MoonIcon />
								</span>
							</label>
						</button>
						{/* <Dropdown className="d-lg-block d-none ms-sm-4 ms-3 header_dropdown">
							<Dropdown.Toggle>
								<SettingsIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<ul>
									<li>
										<label onClick={() => dispatch(setTheme(theme !== "light" ? "light" : "dark"))} className={`${theme === "light" ? "light" : "dark"} theme_toggler`}>
											Dark Mode
											<span className="theme_toggler_btn">
												<SunIcon />
												<motion.span layout></motion.span>
												<MoonIcon />
											</span>
										</label>
									</li>
								</ul>
							</Dropdown.Menu>
						</Dropdown> */}
						<button onClick={handleSidebar} className={`sidebar_toggle ${active ? "active" : " "} d-lg-none`}>
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
				</div>
			</Container>
		</header>
	);
}

export default Header;
