import { animate, motion, stagger, useInView } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

const RoadmapBox = ({ title, list }) => {
    const ref = useRef(null);
    const width = document.body.clientWidth;
    const isInView = useInView(ref);
    const variant = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
        }
    };
    useEffect(() => {
        if (width > 767 && isInView) {
            animate(`#phase-${title} li`, {
                x: [100, 0],
                opacity: [0, 1]
            }, {
                delay: stagger(0.05),
            })
        }
    }, [isInView, width, title])
    return (
        <>
            <motion.div
                animate={isInView ? "show" : ""}
                initial={"hidden"}
                variants={width > 767 && variant}
                key={title}
                ref={ref}
            >
                <h3>Phase {title}</h3>
                <ul id={`phase-${title}`}>
                    {
                        list.map(item => (
                            <li key={item}>
                                <span>{item}</span>
                            </li>
                        ))
                    }
                </ul>
            </motion.div>
        </>
    )
}

export default RoadmapBox
