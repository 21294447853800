import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import automation from "../../../../assets/images/features/automation.png";
import diversification from "../../../../assets/images/features/diversification.png";
import transparency from "../../../../assets/images/features/transparency.png";
import lowerCost from "../../../../assets/images/features/lower-cost.png";
import riskFree from "../../../../assets/images/features/risk-free.png";
import multichain from "../../../../assets/images/features/multichain.png";
import lendingBorrow from "../../../../assets/images/features/lending-borrow.png";
import FeatureCard from './FeatureCard';
import "./Features.scss";
import TitleText from '../../../common/TitleText/TitleText';

const Features = () => {
    return (
        <>
            <section id="features" className="features">
                <Container>
                    <TitleText>Features</TitleText>
                    <Row className='justify-content-center'>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Diversification"
                                description={`"Diversify Your Portfolio with FigFi:Seamlessly explore and invest in a range of assets, enhancing the resilience and potential of your investment strategy. Experience a new dimension of financial flexibility and growth."`}
                                imgs={[diversification]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Transparency "
                                description={`FigFi is committed to transparency. Every loan, its terms, and current status are on chain and available in the dashboard, providing investors with the information needed for strategic capital allocation."`}
                                imgs={[transparency]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Lower Costs"
                                description={`FigFi reduces traditional expenses, making premium investments, like a stake in thriving real estate markets, accessible with an entry as low as $1000 or invest for a stake in revenue of small businesses`}
                                imgs={[lowerCost]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Automation"
                                description={`Smart Portfolio Management, Unlock advanced automation for your real-world asset investments with FigFi. From intelligent asset allocation to automated rebalancing, FigFi takes your portfolio management to the next level, ensuring your investments stay on track effortlessly`}
                                imgs={[automation]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Risk Free"
                                description={`"Long-Term Confidence: Invest and stake with assurance in real-world assets on FigFi. Beyond the yields from Real World Assets (RWA), FigFi rewards your commitment with Fig tokens. Elevate your safety net with our insurance-backed approach, offering a secure and sustainable path for long-term financial growth."`}
                                imgs={[riskFree]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Multichain"
                                description={` FigFi seamlessly integrates real-world assets from different blockchains, providing you with diverse investment opportunities on a unified platform."`}
                                imgs={[multichain]}
                            />
                        </Col>
                        <Col lg={4} md={6}>
                            <FeatureCard
                                title="Lending and Borrowing"
                                description={` Access affordable DeFi capital on FigFi, eliminating banks and intermediaries. Explore diversified and stable yields backed by real-world assets such as invoices, real estate, revenue-based financing, providing a flexible and robust financial solution. FigFi revolutionizes lending and borrowing, putting financial control back in your hands for a tailored and secure journey`}
                                imgs={[lendingBorrow]}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Features
