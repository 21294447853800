import React from 'react'
import Banner from './Banner/Banner'
import QuoteText from './QuoteText/QuoteText'
import Vision from './Vision/Vision'
import Features from './Features/Features'
// import Tokenomics from './Tokenomics/Tokenomics'
import Roadmap from './Roadmap/Roadmap'

const Home = () => {
    return (
        <>
            <Banner />
            <QuoteText />
            <Vision />
            <Features />
            {/* <Tokenomics /> */}
            <Roadmap />
        </>
    )
}

export default Home
